import {
  AccountBalanceOutlined,
  CreditScore,
  HistoryEduOutlined,
  LanguageOutlined,
  LocalShippingOutlined,
  VerifiedOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@renderer-ui-library/mui'

const iconsMap: Record<string, typeof SvgIcon> = {
  creditcardIcon: CreditScore,
  worldIcon: LanguageOutlined,
  transportIcon: LocalShippingOutlined,
  verifiedIcon: VerifiedOutlined,
  certificateIcon: HistoryEduOutlined,
  bankIcon: AccountBalanceOutlined,
}

const matchIconAndText = /^(\[(.*)\]:\s*){0,1}(.*)/
export const parseIconString = (
  text: string
): { text: string; icon: any | null } => {
  const items = text.trim().match(matchIconAndText)
  if (!items) {
    return {
      icon: null,
      text: '',
    }
  }

  return {
    icon: iconsMap[items[2]] ?? null,
    text: items[3] ?? '',
  }
}
