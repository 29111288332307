import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { parseIconString } from 'utils/text/parseIconString'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import { USPItem } from './USPItem/USPItem'
import styles from './uspHeader.module.scss'

const maxUspItems = 5
export const USPHeader: React.FC = memo(() => {
  const { t } = useTranslation(localeNamespace.common)

  const usps = useMemo(
    () =>
      t(translations.uspHeader)
        .split('|')
        .map(parseIconString)
        .filter((usp) => !!usp.text)
        .slice(0, maxUspItems),
    [t]
  )

  return (
    <div className={styles.wrapper}>
      <HeaderContentWrapper className={styles.contentWrapper}>
        <div className={styles.uspHeader}>
          <div className={styles.content}>
            {usps.map((usp) => (
              <USPItem
                key={usp.text}
                text={usp.text}
                icon={usp.icon || undefined}
              />
            ))}
          </div>
        </div>
      </HeaderContentWrapper>
    </div>
  )
})

USPHeader.displayName = 'USPHeader'
