import { SvgIconComponent } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import classnames from 'classnames'
import styles from './uspItem.module.scss'

type Props = {
  text: string
  className?: string
  icon?: SvgIconComponent
}

export const USPItem: React.FC<Props> = (props) => {
  if (!props.text) {
    return null
  }

  const IconComponent = props.icon

  return (
    <Typography
      className={classnames(styles.item, props.className)}
      variant='body2'
      color={Colors.primary}
    >
      {!!IconComponent && (
        <span className={styles.icon}>
          <IconComponent color='inherit' fontSize='inherit' />
        </span>
      )}
      {props.text}
    </Typography>
  )
}
